@import 'colors';
@import 'mixin';

.rw {
  @extend .responsive-width;
}

.social-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -10px;

  &__button {
    margin: 10px 5px 0;
  }
}

$social-button-size: 70px;
$social-button-icon-size: 0.4;
$social-button-background: #fff;
$social-button-active-color: #fff;
$social-button-transition-time: 0.3s;

$social-button-colors: (
  'mail': #0072c6,
  'facebook': #3b5999,
  'linkedin': #0077b5,
  'github': #6e5494,
  'codepen': #212121,
  'docs': #212121,
  'steam': #7da10e,
  'snapchat': #eec900,
  'twitter': #55acee,
  'instagram': #e4405f,
  'npmjs': #c12127,
  'discord': #7289da
);

.social-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  width: $social-button-size;
  height: $social-button-size;
  text-decoration: none;
  border-radius: 100%;
  background: $social-button-background;
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    top: -1px;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    border-radius: 100%;
    transition: $social-button-transition-time;
  }

  &:focus,
  &:hover {
    color: $social-button-active-color;

    &::after {
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      margin-left: calc(-50% - 1px);
    }
  }

  i,
  svg {
    position: relative;
    z-index: 1;
    transition: $social-button-transition-time;
  }

  i {
    font-size: $social-button-size * $social-button-icon-size;
  }

  svg {
    height: percentage($social-button-icon-size);
    width: percentage($social-button-icon-size);
  }

  @each $name, $color in $social-button-colors {
    &--#{$name} {
      color: $color;

      &::after {
        background: $color;
      }
    }
  }
}
