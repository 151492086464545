@import '../../../../styles/colors';
@import '../../../../styles/mixin';

$animationDuration: 120s;
$amount: 5;
$animationMin: 120s;

.CloudsLr {
  @for $i from 1 through $amount {
    span {
      &:nth-child(#{$i}) {
        position: fixed;
        z-index: $zi-clouds;
        top: random(90) * 1%;
        -webkit-animation: linear infinite;
        animation: linear infinite;
        -webkit-animation-name: run;
        animation-name: run;
        -webkit-animation-duration: (random($animationDuration * 10) / 10) *
          1s +
          $animationMin;
        animation-duration: (random($animationDuration * 10) / 10) *
          1s +
          $animationMin;
        -webkit-animation-delay: random(
            ($animationDuration + $animationMin) * 10
          ) /
          10 *
          -1s;
        animation-delay: random(($animationDuration + $animationMin) * 10) /
          10 *
          -1s;
        img {
          width: 100px;
        }
      }
    }
  }
}

@keyframes run {
  0% {
    left: -100px;
  }
  100% {
    @include calc('left', '100% + 100px');
  }
}
