@import '../../../../styles/colors';
@import '../../../../styles/mixin';

$bushesAmount: 10;

.Bushes {
  span {
    position: fixed;
    z-index: $zi-flora;
    @for $i from 0 through $bushesAmount {
      &:nth-child(#{$i}) {
        top: random(100) * 1%;
        @media screen and (min-width: 320px) {
          left: random(70) * 1% + 60%;
        }
        @media screen and (min-width: 480px) {
          left: random(70) * 1% + 50%;
        }
        @media screen and (min-width: 768px) {
          left: random(70) * 1% + 40%;
        }
        @media screen and (min-width: 1024px) {
          left: random(70) * 1% + 30%;
        }
      }
    }
    &:nth-child(1) {
      top: random(100) * 1%;
      left: 5%;
    }
    &:nth-child(2) {
      top: random(100) * 1%;
      left: 1%;
    }
    img {
      width: 50px;
    }
  }
}

.Trees {
  span {
    position: fixed;
    z-index: $zi-flora;
    @for $i from 0 through $bushesAmount {
      &:nth-child(#{$i}) {
        top: random(100) * 1%;
        @media screen and (min-width: 320px) {
          left: random(70) * 1% + 60%;
        }
        @media screen and (min-width: 480px) {
          left: random(70) * 1% + 50%;
        }
        @media screen and (min-width: 768px) {
          left: random(70) * 1% + 40%;
        }
        @media screen and (min-width: 1024px) {
          left: random(70) * 1% + 30%;
        }
      }
    }
    img {
      width: 50px;
    }
  }
}
