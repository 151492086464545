@import '../../../../styles/colors';
@import '../../../../styles/mixin';

.Bkg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $zi-bkg;
  background-image: url('../../../../assets/bkg.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
