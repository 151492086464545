@import '../../../../styles/colors';
@import '../../../../styles/mixin';

.RoadBkg {
  position: fixed;
  top: 0;
  left: 20%;
  width: 100px;
  height: 100%;
  z-index: $zi-road;
  background: rgba($road-bkg, 0.5);
  @extend .bsbb;
  padding: 0 10px;
}

.Road {
  width: 100%;
  height: 100%;
  background: $road-bkg;
  border-left: 5px solid $road-border;
  border-right: 5px solid $road-border;
  @extend .bsbb;
  position: relative;
}

.Line {
  width: 3px;
  border-left: 3px dashed $road-line;
  top: 0;
  margin: auto;
  height: 100%;
}
