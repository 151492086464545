@import '../../../../styles/colors';
@import '../../../../styles/mixin';

$animationDuration: 1s;

$yellowDelay: 9s;
$orangeDelay: 17s;
$redDelay: 18s;
$greenDelay: 24s;

.MarkerYellow {
  position: fixed;
  left: 20%;
  top: 80%;
  z-index: $zi-marker;
  width: 100px;
  @extend.bsbb;
  @extend .bsbb;
  padding: 0 0 0 45px;
  img {
    width: 50px;
    opacity: 0;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: puff;
    animation-name: puff;
    animation-duration: $animationDuration;

    -webkit-animation-delay: $yellowDelay;
    animation-delay: $yellowDelay;
  }
}

.MarkerGreen {
  @extend .MarkerYellow;
  top: 10%;
  img {
    -webkit-animation-delay: $greenDelay;
    animation-delay: $greenDelay;
  }
}

.MarkerRed {
  @extend .MarkerYellow;
  top: 35%;
  padding: 0 0 0 5px;
  img {
    -webkit-animation-delay: $redDelay;
    animation-delay: $redDelay;
  }
}

.MarkerOrange {
  @extend .MarkerRed;
  top: 30%;
  img {
    -webkit-animation-delay: $orangeDelay;
    animation-delay: $orangeDelay;
  }
}

@keyframes puff {
  0% {
    opacity: 0;
    // width: 0;
  }
  100% {
    opacity: 1;
    // width: 50px;
  }
}
