@import '../../../../styles/colors';
@import '../../../../styles/mixin';

$animationDuration: 30s;

/* Right */
$blueDelay: 0s;
$redDelay: 13s;
/* Left */
$orangeDelay: 5s;
$greenDelay: 25s;

.CarBlue {
  position: fixed;
  left: 20%;
  top: 100%;
  z-index: $zi-cars;
  width: 100px;
  @extend .bsbb;
  padding: 0 10px 0 54px;
  -webkit-animation: linear infinite;
  animation: linear infinite;
  -webkit-animation-name: runBottomTop;
  animation-name: runBottomTop;
  animation-duration: $animationDuration;
  -webkit-animation-delay: $blueDelay;
  animation-delay: $blueDelay;
  img {
    width: 30px;
  }
}

.CarRed {
  @extend .CarBlue;
  -webkit-animation-delay: $redDelay;
  animation-delay: $redDelay;
}

.CarOrange {
  position: fixed;
  left: 20%;
  top: -20%;
  z-index: $zi-cars;
  width: 100px;
  @extend .bsbb;
  padding: 0 10px 0 14px;
  -webkit-animation: linear infinite;
  animation: linear infinite;
  -webkit-animation-name: runTopBottom;
  animation-name: runTopBottom;
  animation-duration: $animationDuration;
  -webkit-animation-delay: $orangeDelay;
  animation-delay: $orangeDelay;
  img {
    width: 30px;
  }
}

.CarGreen {
  @extend .CarOrange;
  -webkit-animation-delay: $greenDelay;
  animation-delay: $greenDelay;
}

@keyframes runTopBottom {
  0% {
    top: -20%;
  }
  100% {
    top: 120%;
  }
}

@keyframes runBottomTop {
  0% {
    top: 120%;
  }
  100% {
    top: -20%;
  }
}

// $particleSize: 20vmin;
// $animationDuration: 20s;
// $amount: 20;

// .Background {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100vh;
//   z-index: $zi-bkg;
//   background: $navy; // #3e1e68;
//   span {
//     width: $particleSize;
//     height: $particleSize;
//     border-radius: $particleSize;
//     backface-visibility: hidden;
//     position: absolute;
//     animation-name: move;
//     animation-duration: $animationDuration;
//     animation-timing-function: linear;
//     animation-iteration-count: infinite;
//     $colors: (
//       $notification-info,
//       $notification-success,
//       $notification-warning,
//       $notification-error
//     );
//     @for $i from 1 through $amount {
//       &:nth-child(#{$i}) {
//         color: nth($colors, random(length($colors)));
//         top: random(100) * 1%;
//         left: random(100) * 1%;
//         animation-duration: (random($animationDuration * 10) / 10) * 1s + 10s;
//         animation-delay: random(($animationDuration + 10s) * 10) / 10 * -1s;
//         transform-origin: (random(50) - 25) * 1vw (random(50) - 25) * 1vh;
//         $blurRadius: (random() + 0.5) * $particleSize * 0.5;
//         $x: if(random() > 0.5, -1, 1);
//         box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
//       }
//     }
//   }
// }

// @keyframes move {
//   100% {
//     transform: translate3d(0, 0, 1px) rotate(360deg);
//   }
// }
