@import '../../../styles/colors';
@import '../../../styles/mixin';

.LoaderBkg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/bkg.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: $zi-page-loader;
  @extend .display-flex;
  @extend .flex-center;
}

.Logo {
  width: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
}

.Loader {
  border: 2px solid transparent;
  border-radius: 50%;
  border-top: 2px solid $white;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
